import React from 'react';
import ContactForm from '../components/sections/ContactForm';

class Kontakt extends React.Component {

  render() {

    return (
      <React.Fragment>
        <ContactForm topDivider className="illustration-section-01" />
      </React.Fragment>
    );
  }
}

export default Kontakt;
