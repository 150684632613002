import React from 'react';
// import sections
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';

class About extends React.Component {

  render() {

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h1 className="mt-0 center-content">Experten als Partner</h1>
            <p>
              LIQIMO fungiert als langjähriger Immobilienpartner. Sie erhalten beim Teilverkauf unmittelbar neue Liquidität aber bleiben Mehrheitseigentümer der Immobilie. Wir sind lediglich stiller Partner im Hintergrund - Sie haben volle Flexibilität und Entscheidungshoheit.
            </p>
            <p>
              Am Ende der Immobilienpartnerschaft unterstützt Sie LIQIMO bei den Prozessen rund um Ihre Immobilie. Wir übernehmen die professionelle Vermarktung und den Verkauf der gesamten Immobilie. Durch unsere Fachkompetenz und unser Netzwerk im Immobilienmarkt können wir den bestmöglichen Preis für Sie erzielen. Durch unsere Partnerschaft teilen wir das gleiche Interesse und zwar den größtmöglichen Ertrag beim Verkauf zu erzielen.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/image-placeholder.jpg')}
                alt="Placeholder"
                width={712}
                height={400} />
            </figure>
          </div>
        </GenericSection>

        <Team topDivider />
        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default About;
