import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';

class Impressum extends React.Component {

  render() {

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h1 className="mt-0 center-content">Impressum</h1>
            <h3 className="mt-0 center-content">
              Informationen gem § 5 Abs 1 ECG
            </h3>
            <p>
              LIQIMO GmbH
              <br />
              Lehargasse 7, A-1060 Wien
              <br />
              FN 563782 t des HG Wien
              <br />
              Geschäftsführer: Mag. Thomas Deutinger, BSc
            </p>
            <h3 className="center-content">Urheberrecht</h3>
            <p>
              Die Inhalte dieser Internetpräsenz (Texte, Design, Grafiken,
              Bilder und Dokumente) unterliegen dem Urheberrecht. Eine
              Verbreitung der Inhalte, die über das gesetzlich Zulässige – wie
              das Zitieren – hinausgeht, bedarf eine ausdrückliche Genehmigung
              des Erstellers.
            </p>
          </div>
        </GenericSection>

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Impressum;
