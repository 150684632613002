import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import Pricing from "../components/sections/Pricing";

class Umkehrhypothek extends React.Component {

  render() {

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h1 className="mt-0 center-content">Umkehrhypothek</h1>
            <p>
              Bei der Umkehrhypothek handelt es sich um eine Sonderform des
              Kredites, der nicht auf einmal, sondern in Raten ausgezahlt wird.
              Beim Ableben des Kreditnehmers muss der gesamte Kredit und Zinsen
              auf einmal zurückgezahlt werden.
            </p>
            <p>
              <b>Nachteil:</b>
              <br /> Sie bleiben zwar Eigentümer der Immobilie, verschulden sich
              aber im Alter. Diese Schuld geht nach dem Ableben auf Ihre Erben
              über und stellt eine erhebliche Belastung für diese dar. Die
              Schuld können die Erben in der Regel nur durch einen Verkauf der
              gesamten Immobilie begleichen.
            </p>
          </div>
        </GenericSection>

        <Pricing hasBgColor />
      </React.Fragment>
    );
  }
}

export default Umkehrhypothek;
