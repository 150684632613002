import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";
import Radio from "../elements/Radio";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import Image from "../elements/Image";
import { Redirect } from "react-router-dom";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let priceHubbleAccess = {
  access_token: "",
  expired_in: 0,
};

function toGermanCurrency(number) {
  if (number === "") {
    return "";
  }
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(number);
}

async function getPriceHubbleValuation(data) {
  if (priceHubbleAccess.expired_in < Date.now()) {
    // if(true){
    let [error, response] = await setPriceHubbleToken();
    if (error !== null) {
      return [error, null];
    }

    priceHubbleAccess.access_token = response.access_token;
    priceHubbleAccess.expired_in = new Date(
      Date.now() + response.expires_in * 1000
    ).getTime(); // seconds to mileseconds
  }
  return await getPropertyValuation(data);
}

async function setPriceHubbleToken() {
  let status = null;
  const URL = "https://api.pricehubble.com/auth/login/credentials";
  return fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username: process.env.REACT_APP_PRICE_HUBBLE_USERNAME,
      password: process.env.REACT_APP_PRICE_HUBBLE_PASSWORD,
    }),
  })
    .then((response) => {
      status = response.status;
      return response.json();
    })
    .then((data) => {
      if (status === 200) {
        return [null, data];
      } else if (status === 401 && priceHubbleAccess.expired_in > 0) {
        priceHubbleAccess.expired_in = 0;
        return setPriceHubbleToken();
      } else {
        return [
          {
            code: data.error,
            detail: data.error_description,
          },
          [],
        ];
      }
    })
    .catch((err) => {
      return [
        {
          code: "ERROR",
          detail: err.statusText,
        },
        [],
      ];
    });
}

async function getPropertyValuation(data) {
  let status = null;
  const URL =
    "https://api.pricehubble.com/api/v1/valuation/property_value_light?access_token=" +
    priceHubbleAccess.access_token;
  const dataObj = {
    dealType: "sale",
    countryCode: "AT",
    property: {
      location: {
        address: {
          city: data.property_location_state,
          houseNumber: data.property_location_house_no,
          postCode: data.property_location_postal,
          street: data.property_location_street,
        },
      },
      propertyType: {
        code: data.property_type,
      },
      buildingYear: parseInt(data.property_constructed_year),
      livingArea: parseInt(data.property_living_space),
      condition: data.property_condition,
    },
  };

  if (data.property_type !== "apartment") {
    dataObj.property.landArea = parseInt(data.property_land_area);
  }

  //console.log("Data we are sending", dataObj)
  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataObj),
  })
    .then((response) => {
      status = response.status;
      return response.json();
    })
    .then((data) => {
      if (status === 200) {
        return [null, data];
      } else {
        return [
          {
            code: "PRICE_HUBBLE_ERROR",
            detail: data.message,
          },
          [],
        ];
      }
    })
    .catch((err) => {
      return [
        {
          code: data.error,
          detail: err.statusText,
        },
        [],
      ];
    });
}

async function subscribeForMailchimp(obj) {
  const URL = process.env.REACT_APP_MAILCHIMP_SUBSCRIBE_PAGE;

  let condition = "Renovation Needed";
  if (obj.property_condition === "well_maintained") {
    condition = "Well Maintained";
  } else if (obj.property_condition === "new_or_recently_renovated") {
    condition = "New or recently renovated";
  }

  let monthlyRent = (0.06 * obj.desiredPayout) / 12;
  let ownerShare = Math.round(
    ((obj.PRICEHUBBLE - obj.desiredPayout) / obj.PRICEHUBBLE) * 100
  );
  let OurShare = Math.round((obj.desiredPayout / obj.PRICEHUBBLE) * 100);

  const formattedObj = {
    email: obj.contact_email,
    fields: {
      FNAME: obj.contact_firstname,
      LNAME: obj.contact_lastname,
      PHONE: obj.contact_phone,
      // BIRTHDAY : obj.contact_firstname,
      BIRTHYEAR: obj.contact_birth_year === 0 ? "" : obj.contact_birth_year,
      PARTNER: obj.contact_joint_living,
      PBIRTHYEAR:
        obj.contact_partner_birth_year === 0
          ? ""
          : obj.contact_partner_birth_year,
      GENDER: obj.contact_salutation,
      TITLE: obj.contact_title,
      PROPVALUE: obj.propertyPrice,
      INSTPAY: toGermanCurrency(obj.desiredPayout),
      RENT: toGermanCurrency(monthlyRent),
      OWNERSHARE: ownerShare + "%",
      OURSHARE: OurShare + "%",
      ADDRESS: {
        addr1:
          obj.property_location_house_no + " " + obj.property_location_street,
        city: obj.property_location_state,
        state: obj.property_location_state,
        zip: obj.property_location_postal,
        country: "Austria",
      },
      PROPTYPE: obj.property_type === "house" ? "House" : "Apartment Building",
      CONSTYEAR: obj.property_constructed_year,
      LIVSPACE: parseInt(obj.property_living_space),
      LANDAREA: parseInt(obj.property_land_area),
      CONDITION: condition,
      PRICEHUBBL: toGermanCurrency(obj.PRICEHUBBLE),
      CONFIDENCE: obj.CONFIDENCE,
      STARTRANGE: obj.STARTRANGE,
      ENDRANGE: obj.ENDRANGE,
    },
  };
  //console.log("Data we are sending to mailchimp", formattedObj)
  return fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formattedObj),
  })
    .then((response) => response.json())
    .then((data) => {
      //console.log("data we are receiving form mailchimp", data)
      if (data.status === 1) {
        return [null, data];
      } else {
        return [
          {
            detail: data.message,
          },
          [],
        ];
      }
    })
    .catch((err) => {
      return [
        {
          code: "ERROR",
          detail: err.statusText,
        },
        [],
      ];
    });
}

function ShowAlterMessage(props) {
  if (typeof props.message === "undefined" || props.message === "") {
    return "";
  }
  let styleObj = {
    color: "red",
    border: "solid 1px red",
    padding: "10px",
    marginBottom: "40px",
  };
  if (props.messageType === "success") {
    styleObj = {
      color: "green",
      border: "solid 1px green",
      padding: "10px",
      marginBottom: "40px",
    };
  }
  return (
    <div className="alert" style={styleObj}>
      {props.message}
    </div>
  );
}

function SetPrice(props) {
  if (props.amount === "" || props.amount === 0) {
    return <div style={{ color: "#999" }}>{props.default}</div>;
  }

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(props.amount);
}

class LoginForm extends React.Component {
  constructor() {
    super();
    this.state = {
      submitTrigger: false,
      alertMessage: "",
      responseType: "",
      responseMessage: "",
      redirectToSuccess: false,
      changePriceInput: false,
      changeDesiredInput: false,
      propertyPrice: 0,
      desiredPayout: 0,
      property_location_street: "",
      property_location_house_no: "",
      property_location_postal: "",
      property_location_state: "",
      property_type: "",
      property_constructed_year: 0,
      property_living_space: 0,
      property_land_area: 0,
      property_condition: "",
      contact_salutation: "",
      contact_title: "",
      contact_firstname: "",
      contact_lastname: "",
      contact_email: "",
      contact_phone: "",
      contact_joint_living: "",
      contact_birth_year: 0,
      contact_partner_birth_year: 0,
      contact_source: "",
      specialTab: false,
    };
  }

  numDesiredInput = React.createRef();
  numPriceInput = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.stateProps) {
      if (this.props.stateProps.showMailchimpMessage) {
        this.setState({
          alertMessage:
            "Vielen Dank für Ihr Interesse. Für Ihr persönliches Angebot benötigen wir noch ein paar weitere Informationen.",
        });
      }
      if (this.props.stateProps.propertyPrice !== "") {
        this.setState({ propertyPrice: this.props.stateProps.propertyPrice });
      }
      if (this.props.stateProps.desiredPayout !== "") {
        this.setState({ desiredPayout: this.props.stateProps.desiredPayout });
      }
      if (this.props.stateProps.user_email !== "") {
        this.setState({ contact_email: this.props.stateProps.user_email });
      }
    }
  }

  componentDidUpdate() {
    if (this.state.changeDesiredInput) {
      this.numDesiredInput.current.focus();
    }

    if (this.state.changePriceInput) {
      this.numPriceInput.current.focus();
    }
  }

  handleClickButton = async (e) => {
    if (this.state.propertyPrice <= 0) {
      this.setState({ changePriceInput: true });
    } else if (this.state.desiredPayout <= 0) {
      this.setState({ changeDesiredInput: true });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submitTrigger: true });
    let cobject = this.state;

    // reset to default
    this.setState({ responseMessage: "", responseType: "error" });

    delete cobject.responseType;
    delete cobject.responseMessage;
    delete cobject.alertMessage;
    delete cobject.changePriceInput;
    delete cobject.changeDesiredInput;

    const [error, valuation] = await getPriceHubbleValuation(cobject);
    if (error !== null) {
      // show alter message
      this.setState({ submitTrigger: false });
      this.setState({ responseMessage: error.detail, responseType: "error" });
    } else {
      if (valuation.hasOwnProperty("confidence")) {
        let computeHubbleVal = Math.round(
          (parseInt(valuation.valueRange.lower) +
            parseInt(valuation.valueRange.upper)) /
            2
        );
        cobject.PRICEHUBBLE = computeHubbleVal;
        cobject.CONFIDENCE = valuation.confidence;
        cobject.STARTRANGE = valuation.valueRange.lower;
        cobject.ENDRANGE = valuation.valueRange.upper;
        const [error, response] = await subscribeForMailchimp(cobject);
        if (error !== null) {
          // show alter message
          this.setState({ submitTrigger: false });
          this.setState({
            responseMessage: error.detail,
            responseType: "error",
          });
        } else {
          console.log(response);
          this.setState({ redirectToSuccess: true });
        }
      }
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSpecialInput = (e) => {
    if (e.key === "Tab") {
      this.setState({ changeDesiredInput: true, specialTab: true });
      e.preventDefault();
    }
  };

  render() {
    if (this.state.redirectToSuccess) {
      return (
        <Redirect
          to={{
            pathname: "/erfolgreich/",
          }}
        />
      );
    }

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "signin section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "signin-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Erhalten Sie Ihr Angebot",
      paragraph:
        "Für Ihr persönliches Angebot benötigen wir nur einige wenige Angaben zu Ihrer Immobilie und die Höhe Ihrer gewünschten Sofortauszahlung.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <form onSubmit={this.handleSubmit}>
              <ShowAlterMessage
                message={this.state.alertMessage}
                messageType="success"
              />
              <SectionHeader
                tag="h1"
                data={sectionHeader}
                className="center-content"
              />
              <div className="tiles-wrap" style={hrBottom}>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h3 style={marginTop}>Wunschauszahlung</h3>
                    <p>Bitte wählen Sie Ihre Wunschauszahlung.</p>
                  </div>
                </div>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <fieldset>
                      <div className="mb-24">
                        <label className="form-label">
                          Geschätzter Wert der Immobilie *
                        </label>
                        <div
                          className="form-input"
                          onClick={(e) => {
                            this.setState({
                              propertyPrice: this.state.propertyPrice,
                            });
                            this.setState({ changePriceInput: true });
                          }}
                          style={{
                            cursor: "text",
                            display: this.state.changePriceInput ? "none" : "",
                          }}
                        >
                          <SetPrice
                            default="400.000 €"
                            amount={this.state.propertyPrice}
                          />
                        </div>
                        <input
                          type="number"
                          className="form-input"
                          style={{
                            display: this.state.changePriceInput ? "" : "none",
                          }}
                          name="propertyPrice"
                          ref={this.numPriceInput}
                          value={
                            this.state.propertyPrice === 0
                              ? ""
                              : this.state.propertyPrice
                          }
                          onBlur={(e) => {
                            this.setState({ changePriceInput: false });
                          }}
                          onChange={this.handleInput}
                          onKeyDown={this.handleSpecialInput}
                          required
                        />
                      </div>
                      <div className="mb-24">
                        <label className="form-label">
                          Gewünschte Sofortauszahlung *
                        </label>
                        <div
                          className="form-input"
                          onClick={(e) => {
                            this.setState({
                              desiredPayout: this.state.desiredPayout,
                            });
                            this.setState({ changeDesiredInput: true });
                          }}
                          style={{
                            cursor: "text",
                            display: this.state.changeDesiredInput
                              ? "none"
                              : "",
                          }}
                        >
                          <SetPrice
                            default="100.000 €"
                            amount={this.state.desiredPayout}
                          />
                        </div>
                        <input
                          type="number"
                          className="form-input"
                          style={{
                            display: this.state.changeDesiredInput
                              ? ""
                              : "none",
                          }}
                          name="desiredPayout"
                          ref={this.numDesiredInput}
                          value={
                            this.state.desiredPayout === 0
                              ? ""
                              : this.state.desiredPayout
                          }
                          onBlur={(e) => {
                            if (this.state.specialTab) {
                              this.setState({ specialTab: false });
                            } else {
                              this.setState({ changeDesiredInput: false });
                            }
                          }}
                          onChange={this.handleInput}
                          required
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="tiles-wrap" style={hrBottom}>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h3 style={marginTop}>Objektadresse</h3>
                    <p>Wo befindet sich Ihre Immobilie?</p>
                  </div>
                </div>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <fieldset>
                      <div className="form-group" style={gap}>
                        <div className="mb-24">
                          <Input
                            type="text"
                            name="property_location_street"
                            value={this.state.property_location_street}
                            onChange={this.handleInput}
                            label="Straße *"
                            placeholder="Lehargasse"
                            required
                          />
                        </div>
                        <div className="mb-24">
                          <Input
                            type="text"
                            name="property_location_house_no"
                            value={this.state.property_location_house_no}
                            onChange={this.handleInput}
                            label="Hausnummer *"
                            placeholder="7"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group" style={gap}>
                        <div className="mb-24">
                          <Input
                            type="number"
                            name="property_location_postal"
                            value={this.state.property_location_postal}
                            onChange={this.handleInput}
                            label="PLZ *"
                            placeholder="1060"
                            required
                          />
                        </div>
                        <div className="mb-24">
                          <Input
                            type="text"
                            name="property_location_state"
                            value={this.state.property_location_state}
                            onChange={this.handleInput}
                            label="Stadt *"
                            placeholder="Wien"
                            required
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="tiles-wrap" style={hrBottom}>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h3 style={marginTop}>Objektdetails</h3>
                    <p>Anhand der Art der Immobilie berechnen wir den Wert.</p>
                  </div>
                </div>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="radio-tile-group">
                      <div className="input-container">
                        <input
                          id="haus"
                          onChange={this.handleInput}
                          checked={
                            this.state.property_type === "house" ? true : false
                          }
                          value="house"
                          className="radio-button"
                          type="radio"
                          name="property_type"
                          required
                        />
                        <div className="radio-tile">
                          <div className="icon haus-icon">
                            <Image
                              src={require("./../../assets/images/haus.svg")}
                              alt="Haus"
                              width={48}
                              height={48}
                            />
                          </div>
                          <label htmlFor="haus" className="radio-tile-label">
                            Haus
                          </label>
                        </div>
                      </div>

                      <div className="input-container">
                        <input
                          id="wohnung"
                          checked={
                            this.state.property_type === "apartment"
                              ? true
                              : false
                          }
                          onChange={this.handleInput}
                          value="apartment"
                          className="radio-button"
                          type="radio"
                          name="property_type"
                          required
                        />
                        <div className="radio-tile">
                          <div className="icon bike-icon">
                            <Image
                              src={require("./../../assets/images/wohnung.svg")}
                              alt="Haus"
                              width={48}
                              height={48}
                            />
                          </div>
                          <label htmlFor="wohnung" className="radio-tile-label">
                            Zinshaus
                          </label>
                        </div>
                      </div>
                    </div>

                    <fieldset>
                      <div className="mb-24">
                        <Input
                          type="number"
                          name="property_constructed_year"
                          value={
                            this.state.property_constructed_year === 0
                              ? ""
                              : this.state.property_constructed_year
                          }
                          onChange={this.handleInput}
                          min="1800"
                          max={new Date().getFullYear() + 3}
                          label="Baujahr *"
                          placeholder="1960"
                          required
                        />
                      </div>
                      <div
                        className={
                          this.state.property_type === "apartment"
                            ? ""
                            : "form-group"
                        }
                        style={gap}
                      >
                        <div className="mb-24">
                          <Input
                            type="number"
                            name="property_living_space"
                            min="10"
                            max="800"
                            value={
                              this.state.property_living_space === 0
                                ? ""
                                : this.state.property_living_space
                            }
                            onChange={this.handleInput}
                            label="Wohnfläche*"
                            placeholder="m²"
                            required
                          />
                        </div>
                        <div
                          className="mb-24"
                          style={{
                            display:
                              this.state.property_type === "apartment"
                                ? "none"
                                : "",
                          }}
                        >
                          <Input
                            type="number"
                            min="50"
                            max="5000"
                            name="property_land_area"
                            value={
                              this.state.property_land_area === 0
                                ? ""
                                : this.state.property_land_area
                            }
                            onChange={this.handleInput}
                            label="Grundstücksfläche*"
                            placeholder="m²"
                            required={
                              this.state.property_type === "apartment"
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="mb-24">
                        <label className="form-label">Objektart</label><br />
                        <div>
                          <Radio name="objektart">Freistehendes Haus</Radio>
                        </div>
                        <div>
                          <Radio name="objektart">Doppelhaushälfte</Radio>
                        </div>
                        <div>
                          <Radio name="objektart">Reihenmittelhaus</Radio>
                        </div>
                        <div>
                          <Radio name="objektart">Reihenendhaus</Radio>
                        </div>
                      </div>
                      <div className="mb-24">
                        <label className="form-label">Garagenstellplatz</label><br />
                        <span>
                          <Radio name="objektart">Ja</Radio>
                        </span>
                        <span className="ml-16">
                          <Radio name="objektart">Nein</Radio>
                        </span>
                      </div> */}

                      <Select
                        label="Wie ist der Zustand Ihrer Immobilie? *"
                        placeholder="Zustand auswählen"
                        value={this.state.property_condition}
                        onChange={this.handleInput}
                        name="property_condition"
                        required
                      >
                        <option value="renovation_needed">
                          Renovierung erforderlich
                        </option>
                        <option value="well_maintained">Gut erhalten</option>
                        <option value="new_or_recently_renovated">
                          Neu oder kürzlich renoviert
                        </option>
                      </Select>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="tiles-wrap">
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h3 style={marginTop}>Kontaktdaten</h3>
                    <p>Ihr letzter Schritt zum Angebot.</p>
                  </div>
                </div>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <fieldset>
                      <div className="form-group mb-24" style={gap}>
                        <Select
                          id="anrede"
                          labelHidden
                          placeholder="Anrede"
                          value={this.state.contact_salutation}
                          onChange={this.handleInput}
                          name="contact_salutation"
                          required
                        >
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                        </Select>
                        <Select
                          id="titel"
                          labelHidden
                          placeholder="Titel"
                          value={this.state.contact_title}
                          onChange={this.handleInput}
                          name="contact_title"
                        >
                          <option value="Dr.">Dr.</option>
                          <option value="Mag.">Mag.</option>
                          <option value="Dipl. Ing.">Dipl. Ing.</option>
                          <option value="Ing.">Ing.</option>
                          <option value="Uni. Prof.">Uni. Prof.</option>
                        </Select>
                      </div>
                      <div className="form-group" style={gap}>
                        <div className="mb-24">
                          <Input
                            type="text"
                            name="contact_firstname"
                            value={this.state.contact_firstname}
                            onChange={this.handleInput}
                            label="Vorname *"
                            placeholder="Max"
                            required
                          />
                        </div>
                        <div className="mb-24">
                          <Input
                            type="text"
                            name="contact_lastname"
                            value={this.state.contact_lastname}
                            onChange={this.handleInput}
                            label="Nachname *"
                            placeholder="Mustermann"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group" style={gap}>
                        <div className="mb-24">
                          <Input
                            type="email"
                            name="contact_email"
                            value={this.state.contact_email}
                            onChange={this.handleInput}
                            label="E-Mail *"
                            placeholder="name@gmail.com"
                            required
                          />
                        </div>
                        <div className="mb-24">
                          <Input
                            type="text"
                            name="contact_phone"
                            value={this.state.contact_phone}
                            onChange={this.handleInput}
                            label="Telefon *"
                            placeholder="+43 664 1234 567"
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-24">
                        <label className="form-label">
                          Bewohnen Sie die Immobilie mit Ihrem
                          Lebenspartner/Ehepartner? *
                        </label>
                        <br />
                        <span>
                          <Radio
                            name="contact_joint_living"
                            checked={
                              this.state.contact_joint_living === "Yes"
                                ? true
                                : false
                            }
                            onChange={this.handleInput}
                            value="Yes"
                          >
                            Ja
                          </Radio>
                        </span>
                        <span className="ml-16">
                          <Radio
                            name="contact_joint_living"
                            checked={
                              this.state.contact_joint_living === "No"
                                ? true
                                : false
                            }
                            onChange={this.handleInput}
                            value="No"
                          >
                            Nein
                          </Radio>
                        </span>
                        <span className="ml-16">
                          <Radio
                            name="contact_joint_living"
                            checked={
                              this.state.contact_joint_living === "N/A"
                                ? true
                                : false
                            }
                            onChange={this.handleInput}
                            value="N/A"
                          >
                            Keine Angabe
                          </Radio>
                        </span>
                      </div>
                      <div className="mb-24">
                        <Input
                          type="number"
                          name="contact_birth_year"
                          value={
                            this.state.contact_birth_year === 0
                              ? ""
                              : this.state.contact_birth_year
                          }
                          onChange={this.handleInput}
                          label="Ihr Geburtsjahr"
                          placeholder="1950"
                        />
                      </div>
                      <div
                        className="mb-24"
                        style={{
                          display:
                            this.state.contact_joint_living === "Yes"
                              ? ""
                              : "none",
                        }}
                      >
                        <Input
                          type="number"
                          name="contact_partner_birth_year"
                          value={
                            this.state.contact_partner_birth_year === 0
                              ? ""
                              : this.state.contact_partner_birth_year
                          }
                          onChange={this.handleInput}
                          label="Geburtsjahr des Partners"
                          placeholder="1960"
                        />
                      </div>
                      <div className="mb-32">
                        <Select
                          id="anrede"
                          label="Wie sind Sie auf uns aufmerksam geworden?"
                          placeholder="Medium auswählen"
                          onChange={this.handleInput}
                          name="contact_source"
                          value={this.state.contact_source}
                        >
                          <option>Google</option>
                          <option>Facebook</option>
                          <option>Linkedin</option>
                          <option>Zeitung</option>
                          <option>Fernsehen/TV</option>
                          <option>Radio</option>
                          <option>Flyer</option>
                          <option>Veranstaltung</option>
                          <option>Empfehlung</option>
                          <option>Veranstaltung</option>
                          <option>Sonstige</option>
                        </Select>
                      </div>
                      <div className="signin-footer mb-32">
                        <Checkbox required>
                          Ich habe die Datenschutzerklärung zur Kenntnis
                          genommen und stimme der Weiterverarbeitung meiner
                          Daten durch LIQIMO zu.
                        </Checkbox>
                      </div>
                      <ShowAlterMessage
                        message={this.state.responseMessage}
                        messageType={this.state.responseType}
                      />
                      {this.state.responseType !== "success" && (
                        <div className="mt-24 mb-32">
                          <Button
                            loading={this.state.submitTrigger}
                            color="primary"
                            onClick={this.handleClickButton}
                            wide
                          >
                            Angebot anfordern
                          </Button>
                        </div>
                      )}
                    </fieldset>
                    <div className="signin-bottom has-top-divider">
                      <div className="pt-32 text-xs center-content text-color-low">
                        Haben Sie noch Fragen?{" "}
                        <Link to="/kontakt/" className="func-link">
                          Kontakt aufnehmen
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

// inline style
const marginTop = {
  marginTop: "0px",
  marginBottom: "12px",
};

const hrBottom = {
  paddingBottom: "40px",
  marginBottom: "40px",
  borderBottom: "solid #E7ECF2 1px",
};

const gap = {
  gap: "12px",
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
