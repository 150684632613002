import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ReactTooltip from "react-tooltip";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class News extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: "Sorgenfreie Pension genießen",
      paragraph:
        "Altern im Eigenheim ist die erste Präferenz. Haben auch Sie sich Immobilieneigentum hart erarbeiten müssen? Wollen Sie aber nicht auf Ihre Wünsche des täglichen Lebens verzichten?",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
            />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require("./../../assets/images/news-image-01.jpg")}
                      alt="News 01"
                      width={344}
                      height={194}
                    />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        Bessern Sie Ihre Pension finanziell auf
                      </h3>
                      <p className="mb-16 text-sm">
                        Die Bevölkerung wird laufend älter, ohne dass unser
                        Pensions- und Pflegesystem darauf ausgelegt ist. Fällt
                        auch Ihre Rente trotz langjähriger Beitragszahlungen
                        nicht so hoch aus wie gedacht?
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="/angebot/">Angebot anfordern</a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require("./../../assets/images/news-image-02.jpg")}
                      alt="News 02"
                      width={344}
                      height={194}
                    />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <span
                          data-tip
                          data-for="pflegekosten"
                          className="table-item"
                        >
                          Decken Sie Ihre Pflegekosten{" "}
                          <Image
                            src={require("./../../assets/images/info.svg")}
                            alt="Info Icon"
                            width={32}
                            height={32}
                            className="image-small"
                          />
                        </span>
                      </h3>
                      <ReactTooltip id="pflegekosten" effect="solid">
                        <div style={{ width: "250px" }}>
                          Heimpflege wird staatlich gefördert. Die Bestrebungen
                          der Politik mobile vor stationärer Pflege weiter zu
                          fördern wird sich in Zukunft weiter erhöhen.
                        </div>
                      </ReactTooltip>
                      <p className="mb-16 text-sm">
                        Ein Großteil der Bevölkerung würde die Pflege in der
                        eigenen Wohnung bevorzugen. Die Kosten des Pflegebedarfs
                        oder der Umbaumaßnahmen (um etwa Barrierefreiheit zu
                        ermöglichen) können durch einen Teilverkauf gedeckt
                        werden.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="/angebot/">Angebot anfordern</a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require("./../../assets/images/news-image-03.jpg")}
                      alt="News 03"
                      width={344}
                      height={194}
                    />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        Schaffen Sie Werte für Generationen
                      </h3>
                      <p className="mb-16 text-sm">
                        Mit LIQIMO können Sie und Ihre Liebsten weiterhin von
                        der zukünftigen Wertsteigerung Ihrer Immobilie
                        profitieren. Durch Ihr jederzeitiges Rückkaufsrecht und
                        das Vorkaufsrecht der Erben ist das Immobilieneigentum
                        für Ihren Familienverband abgesichert.
                      </p>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="/angebot/">Angebot anfordern</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;
