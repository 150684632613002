import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/LogoInvert';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer invert-color',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-footer-inner",
              topDivider && "has-top-divider"
            )}
          >
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo className="mb-16" />
                  <div className="footer-copyright">
                    &copy; {new Date().getFullYear()} LIQIMO, alle Rechte
                    vorbehalten
                  </div>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Anschrift</div>
                  <ul className="list-reset mb-0">
                    <li>LIQIMO GmbH</li>
                    <li>Lehargasse 7, A-1060 Wien</li>
                    <li>FN 563782 t des HG Wien</li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Ratgeber</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="/teilverkauf/">Teilverkauf</a>
                    </li>
                    <li>
                      <a href="/gesamtverkauf/">Gesamtverkauf</a>
                    </li>
                    <li>
                      <a href="/leibrente/">Leibrente</a>
                    </li>
                    <li>
                      <a href="/umkehrhypothek/">Umkehrhypothek</a>
                    </li>
                    <li>
                      <a href="/bankkredit/">Bankkredit</a>
                    </li>
                    <li>
                      <a href="/fruchtgenussrecht/">Fruchtgenussrecht</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Mehr Erfahren</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="/ueber-uns/">Über uns</a>
                    </li>
                    <li>
                      <a href="/ueber-uns/#team">Team</a>
                    </li>
                    <li>
                      <a href="/vorteile/">Vorteile</a>
                    </li>
                    <li>
                      <a
                        href="/Liqimo-Broschuere.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Kostenloses Infopaket
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://calendly.com/liqimo/rueckruf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rückruf vereinbaren
                      </a>
                    </li>
                    <li>
                      <a href="/angebot/">Angebot anfordern</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              <FooterNav />
              <FooterSocial />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
