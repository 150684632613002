import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { Doughnut } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import Image from "../elements/Image";

const chartoptions = {
  rotation: 180,
  animation: {
    duration: 0,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
  cutout: 150,
};

const data = {
  datasets: [
    {
      data: [750000, 300000],
      backgroundColor: ["rgba(248, 178, 115, 1)", "rgba(248, 178, 115, 0.4)"],
      borderColor: ["rgba(248, 178, 115, 1)", "rgba(248, 178, 115, 0.4)"],
      borderWidth: 1,
    },
  ],
};

const propTypes = {
  ...SectionTilesProps.types,
  pricingSlider: PropTypes.bool,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSlider: false,
};

class Calculator extends React.Component {
  state = {
    redrawchart: true,
    propertyPrice: 750000,
    desiredPayout: 300000,
    monthly_rent: 1500,
    ownerShare: 60,
    ownerShareAmount: 450000,
    investorShare: 40,
    investorShareAmount: 300000,
    planyear: 10,
    propertyPriceInput: 0,
    changePriceInput: false,
    desiredPriceInput: 0,
    changeDesiredInput: false,
  };

  chart = React.createRef();
  interest_rate = 0.06;

  propertyslider = React.createRef();
  propertysliderValue = React.createRef();

  desiredslider = React.createRef();
  desiredsliderValue = React.createRef();

  yearslider = React.createRef();
  yearsliderValue = React.createRef();

  numDesiredInput = React.createRef();
  numPriceInput = React.createRef();

  componentDidMount() {
    if (this.props.pricingSlider) {
      Doughnut.animation = false;

      this.propertyslider.current.setAttribute("min", 500000);
      this.propertyslider.current.setAttribute("max", 2000000);
      this.propertyslider.current.setAttribute("step", 10000);
      this.propertyslider.current.setAttribute(
        "value",
        this.state.propertyPrice
      );
      this.PropertythumbSize = parseInt(
        window
          .getComputedStyle(this.propertysliderValue.current)
          .getPropertyValue("--thumb-size"),
        10
      );
      this.handlePropertySliderValuePosition(this.propertyslider.current);

      this.desiredslider.current.setAttribute("min", 250000);
      this.desiredslider.current.setAttribute(
        "max",
        this.state.propertyPrice / 2
      );
      this.desiredslider.current.setAttribute("step", 1000);
      this.desiredslider.current.setAttribute(
        "value",
        this.state.desiredPayout
      );
      this.DesiredthumbSize = parseInt(
        window
          .getComputedStyle(this.desiredsliderValue.current)
          .getPropertyValue("--thumb-size"),
        10
      );
      this.handleDesiredSliderValuePosition(this.desiredslider.current);

      this.yearslider.current.setAttribute("min", 10);
      this.yearslider.current.setAttribute("max", 25);
      this.yearslider.current.setAttribute("step", 1);
      this.yearslider.current.setAttribute("value", this.state.planyear);
      this.YearthumbSize = parseInt(
        window
          .getComputedStyle(this.yearsliderValue.current)
          .getPropertyValue("--thumb-size"),
        10
      );
      this.handleYearSliderPosition(this.yearslider.current);

      this.computeSaleDetail(this.state.planyear);
    }
  }

  componentDidUpdate() {
    if (this.state.changeDesiredInput) {
      this.numDesiredInput.current.focus();
    }

    if (this.state.changePriceInput) {
      this.numPriceInput.current.focus();
    }
  }

  caldata = (propertyBased, amount) => {
    let investorratio = 0;
    let yearly_rent = 0;
    let ownerContribution = 0;
    let stateOjb = {};

    if (propertyBased) {
      stateOjb.propertyPrice = amount;

      yearly_rent = this.interest_rate * this.state.desiredPayout;

      stateOjb.monthly_rent = Math.round(yearly_rent / 12);

      investorratio = yearly_rent / (amount * this.interest_rate);
      investorratio = Math.round(investorratio * 100);

      ownerContribution = 1 - investorratio / 100;

      if (ownerContribution < 0.5) {
        // set property data
        stateOjb.ownerShareAmount = amount;

        // set investor data
        let investorContribution = amount / 2;
        stateOjb.investorShareAmount = investorContribution;
        stateOjb.desiredPayout = investorContribution;

        this.desiredslider.current.value = investorContribution;
      } else {
        stateOjb.ownerShare = Math.round(ownerContribution * 100);
        stateOjb.ownerShareAmount = amount - this.state.desiredPayout;
        stateOjb.investorShare = investorratio;
        stateOjb.investorShareAmount = this.state.desiredPayout;
      }

      // change slider limit
      this.desiredslider.current.setAttribute("max", amount / 2);
      this.handleDesiredSliderValuePosition(this.desiredslider.current);
    } else {
      stateOjb.desiredPayout = amount;
      this.chart.current.data.datasets[0].data[1] = amount;

      yearly_rent = this.interest_rate * amount;
      stateOjb.monthly_rent = Math.round(yearly_rent / 12);

      investorratio =
        yearly_rent / (this.state.propertyPrice * this.interest_rate);
      investorratio = Math.round(investorratio * 100);

      ownerContribution = 1 - investorratio / 100;

      stateOjb.ownerShare = Math.round(ownerContribution * 100);
      stateOjb.ownerShareAmount = this.state.propertyPrice - amount;
      stateOjb.investorShare = investorratio;
      stateOjb.investorShareAmount = amount;
    }

    this.setState(stateOjb, function () {
      this.computeSaleDetail(this.state.planyear);
    });

    this.chart.current.data.datasets[0].data[0] = this.state.ownerShare;
    this.chart.current.data.datasets[0].data[1] = this.state.investorShare;
    // this.setState({ redrawchart: true });
  };

  handlePricingSlide = (e) => {
    let temp = e.target.value;
    if (e.target.name === "property-price-slider") {
      this.handlePropertySliderValuePosition(e.target);
      this.caldata(true, temp);
    } else {
      this.caldata(false, temp);
      this.handleDesiredSliderValuePosition(e.target);
    }
  };

  handleinput = (e) => {
    let temp = e.target.value;

    if (e.target.name === "property-price-input") {
      this.setState({ propertyPriceInput: temp });
      if (temp >= 500000 && temp <= 2000000) {
        this.propertyslider.current.value = temp;
        this.propertyslider.current.setAttribute("value", temp);
        this.handlePropertySliderValuePosition(this.propertyslider.current);
        this.caldata(true, temp);
      }
    } else {
      this.setState({ desiredPriceInput: temp });
      if (temp >= 250000 && temp <= this.state.propertyPrice / 2) {
        this.desiredslider.current.value = temp;
        this.desiredslider.current.setAttribute("value", temp);
        this.handleDesiredSliderValuePosition(this.desiredslider.current);
        this.caldata(false, temp);
      }
    }
  };

  computeSaleDetail = (years) => {
    let soldtorelative = false;
    let applicableFee = soldtorelative ? this.interest_rate : 0.045;
    let ValueIncrease = 0.04;
    let TotalSalePrice = Math.round(
      Math.pow(1 + ValueIncrease, years) * this.state.propertyPrice
    );

    let investor_share = Math.round(TotalSalePrice * 0.6);
    let service_fee = TotalSalePrice * applicableFee;

    // let conversioncost = soldtorelative ? 0 : TotalSalePrice * 0.01;
    // let renovation_cost = conversioncost * (this.state.ownerShare / 100);
    // let payoutAmountSecond = investor_share - (renovation_cost + service_fee);
    let payoutAmountSecond = investor_share - service_fee;
    let payoutAmountFirst = this.state.investorShareAmount;
    let usage_fee = years * (this.state.monthly_rent * 12);
    let compared_sales =
      this.state.propertyPrice -
      (this.interest_rate * this.state.propertyPrice * years +
        this.state.propertyPrice * 0.036);
    this.setState({
      property_future_value: TotalSalePrice,
      investor_future_share: investor_share,
      future_service_fee: service_fee,
      // investor_renovationCost: renovation_cost,
      future_first_payout: payoutAmountFirst,
      future_second_payout: payoutAmountSecond,
      company_usage_fee: usage_fee,
      compared_sales: compared_sales,
      total_proceeds:
        parseFloat(payoutAmountFirst) +
        parseFloat(payoutAmountSecond) -
        usage_fee,
    });
  };

  handleYearSlider = (e) => {
    let years = e.target.value;
    this.setState({ planyear: years });
    this.handleYearSliderPosition(e.target);
    this.computeSaleDetail(years);
  };

  handleYearSliderPosition = (input) => {
    const currentStep =
      (input.value - input.min) /
      input.step /
      ((input.max - input.min) / input.step);
    const multiplier = currentStep;
    const thumbOffset = this.YearthumbSize * multiplier;
    const priceInputOffset =
      (this.YearthumbSize - this.yearsliderValue.current.clientWidth) / 2;
    this.yearsliderValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + priceInputOffset + "px";
  };

  handlePropertySliderValuePosition = (input) => {
    const currentStep =
      (input.value - input.min) /
      input.step /
      ((input.max - input.min) / input.step);
    const multiplier = currentStep;
    const thumbOffset = this.PropertythumbSize * multiplier;
    const priceInputOffset =
      (this.PropertythumbSize - this.propertysliderValue.current.clientWidth) /
      2;
    this.propertysliderValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + priceInputOffset + "px";
  };

  handleDesiredSliderValuePosition = (input) => {
    const currentStep =
      (input.value - input.min) /
      input.step /
      ((input.max - input.min) / input.step);
    const multiplier = currentStep;
    const thumbOffset = this.DesiredthumbSize * multiplier;
    const priceInputOffset =
      (this.DesiredthumbSize - this.desiredsliderValue.current.clientWidth) / 2;
    this.desiredsliderValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + priceInputOffset + "px";
  };

  toGerman(number) {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "calculator section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "pricing-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Sehen Sie sich Ihre individuelle Beispielrechnung an",
      paragraph:
        "Berechnen Sie Ihre Sofortauszahlung, mit der Sie ganz einfach Ihre Liquidität verbessern können und trotzdem Herr Ihrer eigenen vier Wände bleiben",
    };

    return (
      <section {...props} className={outerClasses} id="rechner">
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content invert-color"
            />
            <div className="tiles-wrap">
              <div className="tiles-item pr-32">
                <div className="asset-value mb-16">
                  <label className="form-label">Wert der Immobilie</label>
                  <div
                    className="form-input"
                    onClick={(e) => {
                      this.setState({
                        propertyPriceInput: this.state.propertyPrice,
                      });
                      this.setState({ changePriceInput: true });
                    }}
                    style={{
                      cursor: "text",
                      backgroundColor: "white",
                      display: this.state.changePriceInput ? "none" : "",
                    }}
                  >
                    {this.toGerman(this.state.propertyPrice)}
                  </div>
                  <input
                    type="number"
                    min="500000"
                    max="2000000"
                    step="10000"
                    className="form-input"
                    style={{
                      backgroundColor: "white",
                      display: this.state.changePriceInput ? "" : "none",
                    }}
                    name="property-price-input"
                    ref={this.numPriceInput}
                    value={this.state.propertyPriceInput}
                    placeholder="750.000 €"
                    onBlur={(e) => {
                      this.setState({ changePriceInput: false });
                    }}
                    onChange={this.handleinput}
                  />
                </div>
                {pricingSlider && (
                  <div className="pricing-slider center-content mt-16">
                    <label className="form-slider">
                      <input
                        type="range"
                        name="property-price-slider"
                        ref={this.propertyslider}
                        onChange={this.handlePricingSlide}
                      />
                    </label>
                    <div
                      ref={this.propertysliderValue}
                      className="pricing-slider-value"
                    >
                      {this.toGerman(this.state.propertyPrice)}
                    </div>
                  </div>
                )}

                <div className="instant-payout mb-16">
                  <label className="form-label">
                    Gewünschte Sofortauszahlung
                  </label>
                  <div
                    className="form-input"
                    onClick={(e) => {
                      this.setState({
                        desiredPriceInput: this.state.desiredPayout,
                      });
                      this.setState({ changeDesiredInput: true });
                    }}
                    style={{
                      cursor: "text",
                      backgroundColor: "white",
                      display: this.state.changeDesiredInput ? "none" : "",
                    }}
                  >
                    {this.toGerman(this.state.desiredPayout)}
                  </div>
                  <input
                    type="number"
                    className="form-input"
                    style={{
                      backgroundColor: "white",
                      display: this.state.changeDesiredInput ? "" : "none",
                    }}
                    name="desired-payout-input"
                    ref={this.numDesiredInput}
                    value={this.state.desiredPriceInput}
                    placeholder="300.000 €"
                    onBlur={(e) => {
                      this.setState({ changeDesiredInput: false });
                    }}
                    onChange={this.handleinput}
                  />
                </div>
                {pricingSlider && (
                  <div className="pricing-slider center-content mt-16">
                    <label className="form-slider">
                      <input
                        type="range"
                        name="desired-payout-slider"
                        ref={this.desiredslider}
                        onChange={this.handlePricingSlide}
                      />
                    </label>
                    <div
                      ref={this.desiredsliderValue}
                      className="pricing-slider-value"
                    >
                      {this.toGerman(this.state.desiredPayout)}
                    </div>
                  </div>
                )}
                <div class="mt-12 mb-32">
                  Ihr monatlicher Nutzungsbeitrag:{" "}
                  <span className="fw-700">
                    {this.toGerman(this.state.monthly_rent)}
                  </span>
                </div>
                <div className="cta-button">
                  <div className="mt-24 mb-32">
                    <Link
                      to={{
                        pathname: "/angebot/",
                        state: {
                          propertyPrice: this.state.propertyPrice,
                          desiredPayout: this.state.desiredPayout,
                        },
                      }}
                      className="button button-primary button-wide-mobile"
                    >
                      Angebot anfordern
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tiles-item">
                <div
                  className="doughnut-chart"
                  style={{ position: "relative" }}
                >
                  <div className="chart-center-container">
                    <div className="their-share">
                      <div
                        className="share-value"
                        style={{ backgroundColor: "rgba(248, 178, 115, 1)" }}
                      >
                        {this.state.ownerShare}%
                      </div>
                      <div className="share-by">Ihr Anteil</div>
                      <div className="share-detail">
                        Wert: {this.toGerman(this.state.ownerShareAmount)}
                      </div>
                    </div>

                    <div className="their-share" style={{ paddingTop: "15px" }}>
                      <div
                        className="share-value"
                        style={{ backgroundColor: "rgba(248, 178, 115, 0.4)" }}
                      >
                        {this.state.investorShare}%
                      </div>
                      <div className="share-by">LIQIMO Anteil</div>
                      <div className="share-detail">
                        Wert: {this.toGerman(this.state.investorShareAmount)}
                      </div>
                    </div>
                  </div>
                  <Doughnut
                    ref={this.chart}
                    data={data}
                    redraw={this.state.redrawchart}
                    options={chartoptions}
                  />
                </div>
              </div>
            </div>
            <div class="container-sm mt-32">
              <h3 className="center-content">
                Wann planen Sie den Gesamtverkauf Ihrer Immobilie?
              </h3>
              <p className="center-content">
                Eine Immobilienpartnerschaft mit LIQIMO ist grundsätzlich
                unbefristet und läuft ein Leben lang. Sie haben es in der Hand
                ob und wann die Immobilie in Zukunft verkauft wird. <br />
                Wählen Sie die Jahre bis zum Gesamtverkauf:
              </p>
              {pricingSlider && (
                <div className="pricing-slider center-content mt-16">
                  <label className="form-slider">
                    <input
                      type="range"
                      name="year-slider"
                      ref={this.yearslider}
                      onChange={this.handleYearSlider}
                    />
                  </label>
                  <div
                    ref={this.yearsliderValue}
                    className="pricing-slider-value"
                  >
                    {this.state.planyear} Jahre
                  </div>
                </div>
              )}
              <div
                className="table-box"
                style={{ padding: "20px", paddingBottom: "0px" }}
              >
                <ReactTooltip id="total-value" effect="solid">
                  <div style={{ width: "250px" }}>
                    Wir nehmen bei dieser beispielhaften Kalkulation eine
                    Wertentwicklung von 4% p.a. an. Die durchschnittliche
                    Wertentwicklung der letzten 10 Jahre hat bei
                    Einfamilienhäusern 5,8% p.a. betragen.
                  </div>
                </ReactTooltip>
                <ReactTooltip id="service-fee" effect="solid">
                  <div style={{ width: "250px" }}>
                    Das LIQIMO-Serviceentgelt ist eine Kompensation für unsere
                    Aufwendungen (z.B. Kosten der Vertragserrichtung,
                    Notarskosten) und für die Betreuung Ihrer Anliegen während
                    der gesamten Immobilien-Partnerschaft (inkl. der Abwicklung
                    eines möglichen Gesamtverkaufes auf Ihren Wunsch). Das
                    Serviceentgelt entspricht 3,75% des Gesamtwertes zzgl.
                    Umsatzsteuer.
                  </div>
                </ReactTooltip>
                <ReactTooltip id="total-sale" effect="solid">
                  <div style={{ width: "250px" }}>
                    Würden Sie Ihre gesamte Immobilie verkaufen und eine
                    vergleichbare Immobilie anmieten würde sich Ihre Bilanz wie
                    folgt zusammensetzen: Verkehrswert abzüglich Miete für die
                    hier gewählte Anzahl an Jahren, abzüglich Maklerhonorar von
                    3,6%.
                  </div>
                </ReactTooltip>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <span
                          data-tip
                          data-for="total-value"
                          className="table-item"
                        >
                          Gesamtwert der Immobilie nach {this.state.planyear}{" "}
                          Jahren{" "}
                          <Image
                            src={require("./../../assets/images/info.svg")}
                            alt="Info Icon"
                            width={32}
                            height={32}
                            className="image-small"
                          />
                        </span>
                      </td>
                      <td className="ta-r">
                        {this.toGerman(this.state.property_future_value)}
                      </td>
                    </tr>
                    <tr>
                      <td>Ihr Anteil von {this.state.ownerShare}%</td>
                      <td className="ta-r">
                        {this.toGerman(this.state.investor_future_share)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          data-tip
                          data-for="service-fee"
                          className="table-item"
                        >
                          abzüglich Serviceentgelt an LIQIMO &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          <Image
                            src={require("./../../assets/images/info.svg")}
                            alt="Info Icon"
                            width={32}
                            height={32}
                            className="image-small"
                          />
                        </span>
                      </td>
                      <td className="ta-r">
                        - {this.toGerman(this.state.future_service_fee)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>abzüglich allfälliger anteiliger Umbaukosten</td>
                      <td className="ta-r">
                        - {this.toGerman(this.state.investor_renovationCost)}
                      </td>
                    </tr> */}
                    <tr style={{ borderBottom: "0" }}>
                      <td>
                        <h6 className="mt-8">Ihr Anteil am Gesamtverkauf</h6>
                      </td>
                      <td className="ta-r">
                        <h6 className="mt-8">
                          {this.toGerman(this.state.future_second_payout)}
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="table-box mb-32"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  border: "solid #fff 1px",
                }}
              >
                <h4>LIQIMO Bilanz</h4>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Sofortauszahlung</td>
                      <td className="ta-r">
                        {this.toGerman(this.state.future_first_payout)}
                      </td>
                    </tr>
                    <tr>
                      <td>Ihr Anteil am Gesamtverkauf</td>
                      <td className="ta-r">
                        {this.toGerman(this.state.future_second_payout)}
                      </td>
                    </tr>
                    <tr>
                      <td>Gesamter Nutzungsbeitrag</td>
                      <td className="ta-r">
                        - {this.toGerman(this.state.company_usage_fee)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 className="mt-8">Ihr Gesamterlös</h6>
                      </td>
                      <td className="ta-r">
                        <h6 className="mt-8">
                          {this.toGerman(this.state.total_proceeds)}
                        </h6>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "0" }}>
                      <td>
                        <span
                          data-tip
                          data-for="total-sale"
                          className="table-item"
                        >
                          Vergleich zum Gesamtverkauf &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp;
                          <Image
                            src={require("./../../assets/images/info.svg")}
                            alt="Info Icon"
                            width={32}
                            height={32}
                            className="image-small"
                          />
                        </span>
                      </td>
                      <td className="ta-r">
                        {this.toGerman(this.state.compared_sales)}
                      </td>
                    </tr>
                    {/* <tr>
                  <td>
                    compare to total sales: balance after {this.state.planyear}{" "}
                    years
                  </td>
                  <td className="ta-r">
                    {this.toGerman(this.state.future_sales_compare)}
                  </td>
                </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="cta-button center-content">
                <div className="mt-32">
                  <Link
                    to={{
                      pathname: "/angebot/",
                      state: {
                        propertyPrice: this.state.propertyPrice,
                        desiredPayout: this.state.desiredPayout,
                      },
                    }}
                    className="button button-primary button-wide-mobile"
                  >
                    Angebot anfordern
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Calculator.propTypes = propTypes;
Calculator.defaultProps = defaultProps;

export default Calculator;
