import React from 'react';
// import sections
import Blog from "../components/sections/Blog";

class Ratgeber extends React.Component {

  render() {

    return (
      <React.Fragment>
        <Blog postid="0" />
      </React.Fragment>
    );
  }
}

export default Ratgeber;
