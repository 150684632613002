import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Immobilien Teilverkauf',
      paragraph: 'Viele konnten vom Anstieg der Immobilienpreise profitieren. Nur für Eigenheimbesitzer, die weiterhin in ihren eigenen vier Wänden Leben wollen, war dies bislang nicht möglich.'
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              tag="h1"
              className="center-content"
            />
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-bottom"
                    data-reveal-container=".split-item"
                  >
                    Liquidität für den Alltag
                  </h3>
                  <p
                    className="m-0 reveal-from-bottom"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    Um drohende Liquiditätsengpässe im Alter zu vermeiden, gab
                    es bisher die Möglichkeit das gesamte Eigenheim zu verkaufen. Der
                    Immobilien-Teilverkauf mit LIQIMO vereint das Beste aus
                    beiden Welten: Liquidität für den Alltag und die eigenen
                    vier Wände als „Betongold“ zur Altersvorsorge.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/features-split-image-01.jpg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-bottom"
                    data-reveal-container=".split-item"
                  >
                    Finanzielle Freiheit
                  </h3>
                  <p
                    className="m-0 reveal-from-bottom"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    Wer eine Immobilie besitzt, ist zwar auf dem Papier
                    vermögend; mit Immobilienvermögen alleine lassen sich aber
                    keine finanziellen Wünsche des täglichen Lebens erfüllen.
                    Mit LIQIMO können Sie nun erstmals von Ihrem
                    Immobilienvermögen profitieren und Liquidität generieren -
                    ohne das Eigenheim verlassen zu müssen. Dadurch wird das
                    Altern im Eigenheim in finanzieller Freiheit möglich.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/features-split-image-02.jpg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-bottom"
                    data-reveal-container=".split-item"
                  >
                    Vorzüge der Pension
                  </h3>
                  <p
                    className="m-0 reveal-from-bottom"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    LIQIMO unterstützt Sie bei der Erfüllung Ihrer Wünsche im
                    Alter. Der Immobilien-Teilverkauf ermöglicht Ihnen
                    finanzielle Mittel für Reisen oder Haussanierungen.
                    Gleichzeitig schaffen Sie mit Immobilieneigentum bleibende
                    Werte zur Vorsorge für Sie und Ihre Liebsten.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/features-split-image-03.jpg")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
