import React from 'react';
import LoginForm from '../components/sections/LoginForm';

class Angebot extends React.Component {
  render() {
    const { state } = this.props.location
    let stateProps = {
      propertyPrice : "",
      desiredPayout : "",
      user_email : "",
      showMailchimpMessage : false
    }
    
    if(typeof state !== "undefined"){
      stateProps = {
        propertyPrice : typeof state.propertyPrice != "undefined" ? state.propertyPrice : "",
        desiredPayout : typeof state.desiredPayout != "undefined" ? state.desiredPayout : "",
        user_email : typeof state.email != "undefined" ? state.email : "",
        showMailchimpMessage : typeof state.showMailchimpMessage != "undefined" ? state.showMailchimpMessage : false,
      }
    }
    
    return (
      <LoginForm topDivider stateProps={stateProps} className="illustration-section-01" />
    );
  }
}

export default Angebot;
