import React from 'react';
// import sections
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Pricing from "../components/sections/Pricing";

class Teilverkauf extends React.Component {

  render() {

    return (
      <React.Fragment>
        <FeaturesSplit invertMobile imageFill topDivider />

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Die Details zum Teilverkauf</h2>
            <p>
              Durch einen Teilverkauf Ihrer Immobilie macht LIQIMO das benötigte
              Vermögen für Sie verfügbar und überweist Ihnen den gewünschten
              Betrag.
            </p>
            <ul>
              <li>
                LIQIMO kauft bis zu 50% der ideellen Anteile der Immobilie an;
                der Immobilienwert wird dabei von einem unabhängigen,
                zertifizierten Gutachter ermittelt.
              </li>
              <li>
                Zu Ihren Gunsten wird ein Fruchtgenussrecht eingeräumt, welches
                zur Ihrer Absicherung ins Grundbuch eingetragen wird.
              </li>
              <li>
                Nur für den an LIQIMO verkauften Anteil wird ein geringer
                monatlicher Nutzungsbeitrag fällig.
              </li>
              <li>
                Sie entscheiden frei über die Laufzeit der
                Immobilienpartnerschaft. Sollten Sie einen Gesamtverkauf
                wünschen, organisiert LIQIMO die professionelle Vermarktung und
                den Verkauf der gesamten Immobilie, um den bestmöglichen Preis
                für Sie zu erzielen. Alternativ kann der Anteil von LIQIMO von
                Ihnen jederzeit zurückgekauft oder von den Erben erworben
                werden; Sie haben weiterhin volle Flexibilität.
              </li>
            </ul>
          </div>
        </GenericSection>

        <Pricing hasBgColor />
      </React.Fragment>
    );
  }
}

export default Teilverkauf;
