import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Select from "../elements/Select";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

async function subscribeForMailchimp(obj){
  const URL = process.env.REACT_APP_MAILCHIMP_SUBSCRIBE_PAGE

  const formattedObj = {
    email : obj.contact_email,
    fields : {
      GENDER : obj.contact_salutation,
      FNAME : obj.contact_firstname,
      LNAME : obj.contact_lastname,
      MESSAGE : obj.contact_detail
    }
  }
  
  return fetch(URL, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(formattedObj)
  }).then(( response ) => response.json( ))
  .then(( data ) => {
      return [null, data]
  })
  .catch(( err ) => {
    return [{
      code : "ERROR",
      detail : err.statusText,
    }, []];
  })
}


function ShowAlterMessage(props){
  if(typeof(props.message) === "undefined" || props.message === ""){
    return ""
  }
  let styleObj = {color:"red", border:"solid 1px red", padding : "10px", marginBottom: "40px"}
  if(props.messageType === "success"){
    styleObj = {color:"green", border:"solid 1px green", padding : "10px" , marginBottom: "40px"}
  }
  return <div className="alert" style={styleObj}>{props.message}</div>
}

class LoginForm extends React.Component {
  
  constructor() {
    super();
    this.state = {
      alertMessage: "",
      responseType: "",
      responseMessage: "",
      contact_salutation: "",
      contact_firstname: "",
      contact_lastname: "",
      contact_email: "",
      contact_detail: "",
    };
  }

  componentDidMount() {
    
  }

  componentDidUpdate() {

  }

  handleSubmit = async (e) => {
    e.preventDefault()

    this.setState({responseMessage : "", responseType : "error"})
    
    const [error, response] = await subscribeForMailchimp(this.state)
    if(error !== null){
      // show alter message
      this.setState({
        alertMessage : error.detail,
        responseType : "error"
      })
    }else{
      if(response.status === 1){
        let message = "Danke für Ihre Kontaktaufnahme! Wir melden uns in Kürze bei Ihnen."
        if(response.message === "Already Subscribed"){
          message = "Sie haben uns bereits mehrfach mit dieser E-Mail Adresse kontaktiert. Wir melden uns in Kürze bei Ihnen."
        }
        this.setState({responseMessage : message, responseType : "success"})
      }else{
        this.setState({responseMessage : error.detail, responseType : "error"})
      }
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  render() {
    
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Kontakt',
      paragraph: 'Schreiben Sie uns! Wir helfen Ihnen gerne weiter und melden uns in Kürze bei Ihnen.'
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <form onSubmit={this.handleSubmit}>
              <ShowAlterMessage
                message={this.state.alertMessage}
                messageType="success"
              />
              <SectionHeader
                tag="h1"
                data={sectionHeader}
                className="center-content"
              />
              <div className="tiles-wrap">
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <fieldset>
                      <div className="mb-24">
                        <Select
                          id="anrede"
                          labelHidden
                          placeholder="Anrede"
                          value={this.state.contact_salutation}
                          onChange={this.handleInput}
                          name="contact_salutation"
                          required
                        >
                          <option>Herr</option>
                          <option>Frau</option>
                        </Select>
                      </div>
                      <div className="mb-24">
                        <Input
                          type="text"
                          name="contact_firstname"
                          value={this.state.contact_firstname}
                          onChange={this.handleInput}
                          label="Vorname *"
                          placeholder="Max"
                          required
                        />
                      </div>
                      <div className="mb-24">
                        <Input
                          type="text"
                          name="contact_lastname"
                          value={this.state.contact_lastname}
                          onChange={this.handleInput}
                          label="Nachname *"
                          placeholder="Mustermann"
                          required
                        />
                      </div>
                      <div className="mb-24">
                        <Input
                          type="email"
                          name="contact_email"
                          value={this.state.contact_email}
                          onChange={this.handleInput}
                          label="E-Mail *"
                          placeholder="name@gmail.com"
                          required
                        />
                      </div>
                      <div className="mb-24">
                        <Input
                          type="textarea"
                          name="contact_detail"
                          value={this.state.contact_detail}
                          onChange={this.handleInput}
                          label="Ihre Nachricht *"
                          placeholder="Bitte geben Sie hier Ihren Text ein."
                          required
                        />
                      </div>
                      <ShowAlterMessage
                        message={this.state.responseMessage}
                        messageType={this.state.responseType}
                      />
                      {this.state.responseType !== "success" && (
                        <div className="mt-24 mb-32">
                          <Button color="primary" wide>
                            Nachricht senden
                          </Button>
                        </div>
                      )}
                    </fieldset>
                    <div className="signin-bottom has-top-divider">
                      <div className="pt-32 text-xs center-content text-color-low">
                        <a
                          href="https://calendly.com/liqimo/rueckruf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Rückruf vereinbaren
                        </a>{" "}
                        oder{" "}
                        <a
                          href="/Liqimo-Broschuere.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          kostenloses Infopaket runterladen
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
