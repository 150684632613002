import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false
}

class Pricing extends React.Component {

  state = {
    priceChangerValue: "4",
    priceInput: {
      0: "€100.000",
      1: "€200.000",
      2: "€300.000",
      3: "€400.000",
      4: "€500.000",
      5: "€600.000",
      6: "€700.000",
      7: "€800.000",
      8: "€900.000"
    },
    priceOutput: {
      plan1: {
        0: ["$", "0", "/m"],
        1: ["$", "13", "/m"],
        2: ["$", "17", "/m"],
        3: ["$", "21", "/m"],
        4: ["$", "27", "/m"],
        5: ["$", "42", "/m"],
        6: ["$", "58", "/m"],
        7: ["$", "117", "/m"],
        8: ["$", "208", "/m"]
      },
      plan2: {
        0: ["$", "13", "/m"],
        1: ["$", "17", "/m"],
        2: ["$", "21", "/m"],
        3: ["$", "25", "/m"],
        4: ["$", "47", "/m"],
        5: ["$", "58", "/m"],
        6: ["$", "117", "/m"],
        7: ["$", "208", "/m"],
        8: ["$", "299", "/m"]
      },
      plan3: {
        0: ["$", "17", "/m"],
        1: ["$", "21", "/m"],
        2: ["$", "25", "/m"],
        3: ["$", "42", "/m"],
        4: ["$", "67", "/m"],
        5: ["$", "117", "/m"],
        6: ["$", "208", "/m"],
        7: ["$", "299", "/m"],
        8: ["$", "499", "/m"]
      }
    }
  }

  slider = React.createRef();
  sliderValue = React.createRef();

  componentDidMount() {
    if (this.props.pricingSlider) {
      this.slider.current.setAttribute('min', 0);
      this.slider.current.setAttribute('max', Object.keys(this.state.priceInput).length - 1);
      this.thumbSize = parseInt(window.getComputedStyle(this.sliderValue.current).getPropertyValue('--thumb-size'), 10);
      this.handleSliderValuePosition(this.slider.current);
    }
  }

  handlePricingSwitch = (e) => {
    this.setState({ priceChangerValue: e.target.checked ? '1' : '0' });
  }

  handlePricingSlide = (e) => {
    this.setState({ priceChangerValue: e.target.value });
    this.handleSliderValuePosition(e.target);
  }

  handleSliderValuePosition = (input) => {
    const multiplier = input.value / input.max;
    const thumbOffset = this.thumbSize * multiplier;
    const priceInputOffset = (this.thumbSize - this.sliderValue.current.clientWidth) / 2;
    this.sliderValue.current.style.left = input.clientWidth * multiplier - thumbOffset + priceInputOffset + 'px';
  }

  getPricingData = (values, set) => {
    return set !== undefined ? values[this.state.priceChangerValue][set] : values[this.state.priceChangerValue];
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'pricing section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'pricing-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Vergleichen lohnt sich',
      paragraph: 'Sie können auf einen Blick sämtliche Vor- und Nachteile vergleichen und sich für Ihre beste Lösung entscheiden'
    };

    return (
      <section {...props} className={outerClasses} id="rechner">
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content invert-color"
            />
            {pricingSwitcher && (
              <div className="pricing-switcher center-content invert-color">
                <Switch
                  checked={this.state.priceChangerValue === "1" ? true : false}
                  onChange={this.handlePricingSwitch}
                  rightLabel="Billed Annually"
                >
                  Billed Monthly
                </Switch>
              </div>
            )}
            {pricingSlider && (
              <div className="pricing-slider center-content">
                <label className="form-slider">
                  <span className="mb-16">Wert Ihrer Immobilie</span>
                  <input
                    type="range"
                    ref={this.slider}
                    defaultValue={this.state.priceChangerValue}
                    onChange={this.handlePricingSlide}
                  />
                </label>
                <div ref={this.sliderValue} className="pricing-slider-value">
                  {this.getPricingData(this.state.priceInput)}
                </div>
              </div>
            )}
            <div className={tilesClasses}>
              {/* CALCULATOR */}

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h4 text-color-high mb-24">
                        Leibrente
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li>Auszahlung i.d.R. monatlich (Zahlungsrisiko)</li>
                        <li>Auszahlung abhängig von Lebenserwartung</li>
                        <li>Risiko­abschlag vom Marktwert</li>
                        <li>Kein verbleibender Hausanteil</li>
                        <li>Kein Anteil an Wertsteigerung</li>
                        <li>Kein Rückkauf / Vererben möglich</li>
                        <li>Keine Entscheidungshoheit</li>
                        <li>Keine Wert­steigerung</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h4 text-color-high mb-24">
                        Gesamtverkauf
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Auszahlung sofort</li>
                        <li>Abschläge je nach Marktlage</li>
                        <li>Kein verbleibender Hausanteil</li>
                        <li>Kein Anteil an Wertsteigerung</li>
                        <li>Kein Rückkauf / Vererben möglich</li>
                        <li>Keine Entscheidungshoheit</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h4 text-color-high mb-24">
                        LIQIMO
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Auszahlung sofort</li>
                        <li className="is-checked">
                          Lebenserwartung irrelevant
                        </li>
                        <li className="is-checked">
                          Keine Abschläge vom Marktwert
                        </li>
                        <li className="is-checked">
                          Min. 50% verbleibender Hausanteil
                        </li>
                        <li className="is-checked">
                          Wert­steigerung Ihres Anteils
                        </li>
                        <li className="is-checked">
                          Rückkauf / Vererben ist möglich
                        </li>
                        <li className="is-checked">
                          Ihre Entscheidungs­hoheit
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="/angebot/">
                      Angebot anfordern
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
