import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import LandscapeArticle from './partials/LandscapeArticle';
import Article from './partials/Article';
// import Image from "../elements/Image";
import WPAPI from "wpapi";
var wp = new WPAPI({ endpoint: 'https://content.liqimo.at/wp-json' });

const PerPagePosts = 10;

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

async function fetchPostsList(pageNo, postId) {
  if(postId > 0){
    return wp.posts().exclude(postId).embed().perPage(3).then(function( data ) {
      return [null, data]
    }).catch(function( err ) {
      return [err, null]
    });
  }else{
    return wp.posts().embed().perPage(PerPagePosts).page(pageNo).then(function( data ) {
      return [null, data]
    }).catch(function( err ) {
      return [err, null]
    });
  }
}

class Blog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      currentPage: 1
    };
  }

  async fetchPosts(PageNo){
    const [error, response] = await fetchPostsList(PageNo, this.props.postid)
    if(error === null){
      this.setState({currentPage : PageNo})
      this.setState({posts : response})
    }
  }

  async componentDidMount() {
    await this.fetchPosts(this.state.currentPage)
  }

  async componentDidUpdate(prevProps) {
    if(this.props.postid !== prevProps.postid){
      await this.fetchPosts(this.state.currentPage) 
    }
  }

  async goToPage(page){
    await this.fetchPosts(page) 
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "news section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "news-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );
    
    let sectionHeader = {
      title: "Der Ratgeber für Immobilienbesitzer",
      paragraph:
        "Hier finden Sie interessante Artikel zum Thema Immobilien-Teilverkauf und wertvolle Tipps, wie Sie Ihr Leben in Ihrem Eigenheim noch mehr genießen können.",
    };

    if(this.props.postid > 0){
      sectionHeader.title = "Ähnliche Beiträge";
      sectionHeader.paragraph = "Diese Artikel mit weiteren Infos könnten Ihnen auch gefallen.";
    }

    let firstPost = this.state.posts.map((post, index)=> {
      if(this.props.postid <= 0 && index === 0){
        return <LandscapeArticle post={post} />
      }
      return ""
    })

    let otherPosts = this.state.posts.map((post, index)=> {
      if(this.props.postid > 0 || index > 0){
        return <Article post={post} />
      }
      return ""
    })

    const tilesClasses = classNames("tiles-wrap", this.props.pushLeft && "push-left");

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
            />

            {firstPost}

            <div className={tilesClasses}>{otherPosts}</div>

            {this.props.postid <= 0 &&
              typeof this.state.posts._paging !== "undefined" &&
              typeof this.state.posts._paging.prev !== "undefined" && (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goToPage(this.state.currentPage - 1);
                  }}
                >
                  Vorige Seite
                </a>
              )}

            {this.props.postid <= 0 &&
              typeof this.state.posts._paging !== "undefined" &&
              typeof this.state.posts._paging.next !== "undefined" && (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goToPage(this.state.currentPage + 1);
                  }}
                >
                  Nächste Seite
                </a>
              )}
          </div>
        </div>
      </section>
    );
  }
}

Blog.propTypes = propTypes;
Blog.defaultProps = defaultProps;

export default Blog;
