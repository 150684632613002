import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import Pricing from "../components/sections/Pricing";

class Gesamtverkauf extends React.Component {

  render() {

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h1 className="mt-0 center-content">Gesamtverkauf</h1>
            <h3>Ohne Wohnrecht</h3>
            <p>
              Der Eigentümer verkauft sein gesamtes Eigenheim und muss das
              Eigenheim unmittelbar nach dem Verkauf verlassen.
            </p>
            <p>
              <b>Nachteil:</b>
              <br /> Sie müssen Ihr gewohntes Umfeld verlassen und sind im Alter
              dem Umzugsstress ausgesetzt. Zusätzlich fallen für die neue
              Unterkunft teure Mieten und Maklergebühren an. Der Verkauf ist
              endgültig und kann nicht mehr Rückgängig gemacht werden. Weder
              Sie, noch Ihre Erben können daher von der Wertsteigerung der
              Immobilie profitieren. Weiters sind Sie bei diesem Modell nicht
              flexibel und können nur 100% Ihrer Immobilie verkaufen, unabhängig
              wie viel Liquidität Sie tatsächlich benötigen.
            </p>
            <h3>Mit Wohnrecht auf Lebenszeit</h3>
            <p>
              Der Eigentümer verkauft sein gesamtes Eigenheim. Im Unterschied
              zum Gesamtverkauf ohne Wohnrecht kann der Verkäufer bis zum
              Ableben im Eigenheim verbleiben.
            </p>
            <p>
              <b>Nachteil:</b>
              <br /> Da Sie kein Eigentümer der Immobilie mehr sind, können
              weder Sie, noch Ihre Erben von der Wertsteigerung der Immobilie
              profitieren. Weiters sind Sie bei diesem Modell nicht flexibel und
              können nur 100% Ihrer Immobilie verkaufen, unabhängig wie viel
              Liquidität Sie tatsächlich benötigen. Im Übrigen hat bei diesem
              Modell der Käufer oftmals ein wirtschaftliches Interesse an Ihrem
              früheren Auszug aus der Immobilie (Gefahr der Verdrängung).
            </p>
          </div>
        </GenericSection>

        <Pricing hasBgColor />
      </React.Fragment>
    );
  }
}

export default Gesamtverkauf;
