import React from 'react';
import { Link } from "react-router-dom";
import Image from '../../elements/Image';
import Button from "../../../components/elements/Button";

class FirstArticle extends React.Component {
  
  render() {

    let post = this.props.post

    let imagesArr = post._embedded["wp:featuredmedia"]
    let ImageObj = {
        alt_text : "",
        source_url : "",
        height : "",
        width : "",
    }
    if(imagesArr.length > 0){
        ImageObj = {
            alt_text : imagesArr[0].alt_text,
            source_url : imagesArr[0].media_details.sizes.full.source_url,
            height : imagesArr[0].media_details.sizes.full.height,
            width : imagesArr[0].media_details.sizes.full.width,
        }
    }

    // remove tag from text
    var div = document.createElement('div');

    // assing your HTML to div's innerHTML
    div.innerHTML = post.excerpt.rendered;

    // get all <a> elements from div
    var elements = div.getElementsByTagName('a');

    // remove all <a> elements
    while (elements[0])
      elements[0].parentNode.removeChild(elements[0])

    // get div's innerHTML into a new variable
    var excerptText = div.innerHTML;

    return (
      <div className="flex-container" key={post.id}>
        <div className="container-xs blog-post-content-left">
          <div className="tile-item-inner has-shadow ratgeber-bg">
            <figure className="mt-24 mb-16">
              <Link
                to={{
                  pathname: "/post/" + post.slug,
                  state: {
                    postID: post.id,
                  },
                }}
              >
                <Image
                  className="image-normal"
                  src={ImageObj.source_url}
                  alt="Placeholder"
                  width={ImageObj.width}
                  height={ImageObj.height}
                  style={{ "max-height": "300px", "object-fit": "cover" }}
                />
              </Link>
            </figure>
            <div className="ratgeber-item-content">
              <h3 className="news-item-title h4 mt-0 mb-8">
                <Link
                  to={{
                    pathname: "/post/" + post.slug,
                    state: {
                      postID: post.id,
                    },
                  }}
                >
                  {post.title.rendered}
                </Link>
              </h3>
              <p className="mb-16 text-sm">
                {excerptText.replace(/<[^>]+>/g, "")}
              </p>
              <div className="news-item-more text-xs mb-8">
                <Link
                  to={{
                    pathname: "/post/" + post.slug,
                    state: {
                      postID: post.id,
                    },
                  }}
                >
                  Weiterlesen
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section-inner ratgeber-cta mt-24">
          <div className="ratgeber-bg p-24 has-shadow">
            <h4 className="mt-0">Liquidität durch Immobilien-Teilverkauf</h4>
            <p>
              Viele konnten vom Anstieg der Immobilienpreise profitieren. Nur
              für Eigenheimbesitze, die weiterhin in ihren eigenen vier Wänden
              leben wollen, war dies bislang nicht möglich.
            </p>
            <p>
              Der Teilverkauf von bis zu 50% kann das Vermögen aus der Immobilie
              lösen.
            </p>
            <p>Jetzt Ihre finanzielle Freiheit im Eigenheim sichern.</p>
            <br />
            <Button tag="a" color="primary" href="/angebot/" wide>
              Angebot einholen
            </Button>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default FirstArticle;