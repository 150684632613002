import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Tabs, { TabList, Tab, TabPanel } from './../elements/Tabs';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class FeaturesTabs extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tabs section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tabs-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: "Bisheriges Angebot",
      paragraph:
        "Neben dem Immobilien-Teilverkauf, stellen der Gesamtverkauf, die Leibrente und die Umkehrhypothek weitere Option der Schaffung der Liquidität aus der eigenen Immobilie dar.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Tabs active="tab-a">
              <TabList>
                <Tab tabId="tab-a">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("./../../assets/images/features-tabs-icon-01.svg")}
                      alt="Tab icon 01"
                      width={56}
                      height={56}
                    />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Gesamtverkauf
                  </div>
                </Tab>
                <Tab tabId="tab-b">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("./../../assets/images/features-tabs-icon-02.svg")}
                      alt="Tab icon 02"
                      width={56}
                      height={56}
                    />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Leibrente
                  </div>
                </Tab>
                <Tab tabId="tab-c">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("./../../assets/images/features-tabs-icon-03.svg")}
                      alt="Tab icon 03"
                      width={56}
                      height={56}
                    />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Umkehrhypothek
                  </div>
                </Tab>
              </TabList>
              <TabPanel id="tab-a">
                <p className="container-xs">
                  Sie müssen Ihr gewohntes Umfeld verlassen und sind im Alter
                  dem Umzugsstress ausgesetzt. Zusätzlich fallen für die neue
                  Unterkunft teure Mieten und Maklergebühren an. Weder Sie, noch
                  Ihre Erben können von der Wertsteigerung der Immobilie
                  profitieren.
                </p><br/>
                <p className="news-item-more">
                  <a href="/gesamtverkauf">Mehr erfahren</a>
                </p>
              </TabPanel>
              <TabPanel id="tab-b">
                <p className="container-xs">
                  Hier findet ein Gesamtverkauf der Immobilie statt. Sie sind
                  kein Eigentümer mehr und partizipieren auch nicht an der
                  Wertsteigerung der Immobilie.
                </p><br/>
                <p className="news-item-more">
                  <a href="/leibrente">Mehr erfahren</a>
                </p>
              </TabPanel>
              <TabPanel id="tab-c">
                <p className="container-xs">
                  Die Kreditaufnahme im Alter ist schwierig oder nicht mehr
                  möglich. Außerdem möchten sich viele im Alter nicht
                  verschulden, um in der Pension kein Risiko eingehen zu müssen.
                </p><br/>
                <p className="news-item-more">
                  <a href="/umkehrhypothek">Mehr erfahren</a>
                </p>
              </TabPanel>
              <TabPanel id="tab-d">
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/features-tabs-image.png")}
                  alt="Features tabs image 04"
                  width={896}
                  height={504}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTabs.propTypes = propTypes;
FeaturesTabs.defaultProps = defaultProps;

export default FeaturesTabs;
