import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: '4 Schritte zu Ihrer finanziellen Freiheit im Eigenheim',
      paragraph: 'Durch einen Teilverkauf Ihrer Immobilie macht LIQIMO das benötigte Vermögen für Sie verfügbar und überweist Ihnen den gewünschten Betrag.'
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="1. Fordern Sie Ihr Angebot an">
                Nach Beantwortung weniger kurzer Fragen zur Immobilie wird
                automatisch ein vorläufiges Angebot erstellt, das die
                Wertindikation der Immobilie sowie die mögliche Höhe der
                Sofortauszahlung und des Nutzungsbeitrags beinhaltet.
                <div className="mt-24">
                  <a
                    href="/angebot/"
                    className="button button-primary button-wide-mobile"
                  >
                    Angebot anfordern
                  </a>
                </div>
              </TimelineItem>
              <TimelineItem title="2. Holen Sie sich gratis ein unabhängiges Gutachten ein">
                Bequem über Telefon oder Website kann ein Termin zur
                Besichtigung der Immobilie vereinbart werden. Ein unabhängiger,
                zertifizierter Gutachter bewertet die Immobilie vor Ort auf
                Kosten von LIQIMO. Auf Basis des ermittelten Wertes unterbreitet
                LIQIMO dann ein finales und verbindliches Angebot.
              </TimelineItem>
              <TimelineItem title="3. Wir vereinbaren einen Notartermin">
                Wenn das Angebot angenommen wird, vereinbart LIQIMO einen Termin
                bei einem Notar in Ihrem Einzugsgebiet. Der Termin findet in der
                Regel innerhalb von 1-2 Wochen ab Angebotsannahme statt.
              </TimelineItem>
              <TimelineItem title="4. Erhalten Sie die Sofortauszahlung innerhalb von 2 Tagen">
                Die Sofortauszahlung wird vom Notar unmittelbar nach
                Unterfertigung auf Ihr Konto gutgeschrieben. Dadurch ist Ihre
                finanzielle Freiheit gesichert.
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
