import React from 'react';
import {Redirect} from "react-router-dom";
// import sections
import GenericSection from '../components/sections/GenericSection';
import Blog from "../components/sections/Blog";
// import some required elements
import Image from '../components/elements/Image';
import Button from "../components/elements/Button";

import WPAPI from "wpapi";
var wp = new WPAPI({ endpoint: 'https://content.liqimo.at/wp-json' });


async function fetchPostDetail(slug) {
  return wp.posts().slug(slug).then(function( data ) {
    return [null, data[0]]
  }).catch(function( err ) {
    return [err, null]
  });
}

async function fetchPostMedia(id) {
  return wp.media().parent(id).then(function( data ) {
    return [null, data]
  }).catch(function( err ) {
    return [err, null]
  });
}

class Post extends React.Component {
  constructor() {
    super();
    this.state = {
      post_id : 0,
      media: [],
      post: {
        title : {
          rendered : ""
        },
        content : {
          rendered : ""
        }
      }
    };
  }

  async displayPost(slug) {
    const [error, response] = await fetchPostDetail(slug)
    if(error === null){
      this.setState({post : response})
      this.setState({post_id : response.id})
      const [err, resp] = await fetchPostMedia(this.state.post.id)
      if(err === null){
        const itemRows = [];
        let image = null
        for (image of resp) {
          itemRows.push(image.media_details.sizes);
        }

        this.setState({media : itemRows})
      }
    }else{

    }
  }

  async componentDidMount(){
    let hash = this.props.match.params.post_title
    if(hash !== ""){
      await this.displayPost(hash)
    }
  }

  async componentDidUpdate(prevProps) {
    let hash = this.props.match.params.post_title
    if(hash !== "" && hash !== prevProps.match.params.post_title){
      await this.displayPost(hash)
    }
    window.scrollTo(0, 0);
  }

  render() {
    if(typeof(this.props.match.params.post_title) === "undefined"){
      return <Redirect
        to={{
          pathname: "/ratgeber/"
        }}
      />
    }
    
    var ImageObj = {
      source_url : "",
      height : "",
      width : ""
    }
    if(this.state.media.length > 0){
      ImageObj = this.state.media[0].full
    }

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="flex-container">
            <div className="container-xs blog-post-content-left">
              <a href="/ratgeber" className="no-underline">
                <svg
                  width="16"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-rotate"
                >
                  <path
                    d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                    fill="#f8b273"
                  />
                </svg>
                {"  "}
                zurück zum Ratgeber
              </a>
              <figure className="mt-24 mb-24">
                <Image
                  className="image-normal"
                  src={ImageObj.source_url}
                  alt="Placeholder"
                  width={ImageObj.width}
                  height={ImageObj.height}
                />
              </figure>
              <h1 className="mt-0">{this.state.post.title.rendered}</h1>
              <div
                className="mb-32"
                dangerouslySetInnerHTML={{
                  __html: this.state.post.content.rendered,
                }}
              />
              <div className="mt-32 pt-16 has-top-divider">
                <p>
                  <a href="/ratgeber" className="no-underline">
                    <svg
                      width="16"
                      height="12"
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon-rotate"
                    >
                      <path
                        d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                        fill="#f8b273"
                      />
                    </svg>
                    {"  "}
                    zurück zum Ratgeber
                  </a>
                </p>
              </div>
            </div>
            <div className="section-inner blog-post-cta">
              <div className="p-24 has-shadow test-test">
                <h4 className="mt-0">
                  Liquidität durch Immobilien-Teilverkauf
                </h4>
                <p>
                  Viele konnten vom Anstieg der Immobilienpreise profitieren.
                  Nur für Eigenheimbesitze, die weiterhin in ihren eigenen vier
                  Wänden leben wollen, war dies bislang nicht möglich.
                </p>
                <p>
                  Der Teilverkauf von bis zu 50% kann das Vermögen aus der
                  Immobilie lösen.
                </p>
                <p>Jetzt Ihre finanzielle Freiheit im Eigenheim sichern.</p>
                <Button tag="a" color="primary" href="/angebot/" wide>
                  Angebot einholen
                </Button>
              </div>
            </div>
          </div>
        </GenericSection>
        <Blog postid={this.state.post_id} />
      </React.Fragment>
    );
  }
}

export default Post;
