import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import Pricing from "../components/sections/Pricing";

class Bankkredit extends React.Component {

  render() {

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h1 className="mt-0 center-content">Bankkredit</h1>
            <p>
              Ein Bankkredit muss aus den laufenden Einkünften des Kreditnehmers
              zurückgezahlt werden. Der Kredit muss in der Regel bis zum 65.
              Lebensjahr abbezahlt werden.
            </p>
            <p>
              <b>Nachteil:</b>
              <br /> Eine Kreditaufnahme nach dem 60. Lebensjahr wird von den
              Banken üblicherweise abgelehnt.
            </p>
          </div>
        </GenericSection>

        <Pricing hasBgColor />
      </React.Fragment>
    );
  }
}

export default Bankkredit;
