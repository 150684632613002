import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import Pricing from "../components/sections/Pricing";

class Leibrente extends React.Component {

  render() {

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h1 className="mt-0 center-content">Leibrente</h1>
            <p>
              Der Eigentümer verkauft sein gesamtes Eigenheim; ihm wird
              lediglich ein lebenslängliches Wohnrecht eingeräumt. Im
              Unterschied zum <a href="/gesamtverkauf">Gesamtverkauf</a> mit
              Wohnrecht wird bei der Leibrente der Kaufpreis nicht auf einmal
              ausgezahlt, sondern in monatlichen Raten ("Leibrenten") bis zum
              Lebensende.
            </p>
            <p>
              <b>Nachteil:</b>
              <br /> Neben den Nachteilen eines Gesamtverkaufes gleicht das
              Leibrenten-Modell einem Glückspiel - eine der Parteien wird
              wirtschaftlich benachteiligt sein (entweder Sie bei vorzeitigem
              Ableben oder der Käufer bei einer Überschreitung der erwarteten
              Lebensdauer). Um das Langlebigkeitsrisiko zu minimieren, berechnet
              der Käufer einen Sicherheitspuffer, weshalb die Rentenzahlungen
              oft niedriger ausfallen als erwartet. Weiters besteht das Risiko
              einer Zahlungsunfähigkeit des Käufers, so dass Ihre Leibrenten
              nicht mehr gezahlt werden könnten. Im Übrigen hat bei diesem
              Modell der Käufer oftmals ein Interesse an Ihrem früheren Auszug
              aus der Immobilie um weniger Leibrente zu bezahlen (Gefahr der
              Verdrängung).
            </p>
          </div>
        </GenericSection>

        <Pricing hasBgColor />
      </React.Fragment>
    );
  }
}

export default Leibrente;
