import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import {Redirect} from "react-router-dom";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

async function subscribeForMailchimp(contact_email){
  const URL = process.env.REACT_APP_MAILCHIMP_SUBSCRIBE_PAGE
  //console.log(URL)
  const formattedObj = {
    email : contact_email
  }

  return fetch(URL, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(formattedObj)
  }).then(( response ) => response.json( ))
  .then(( data ) => {
    if(data.status === 1){
      return [null, data]
    }else{
      return [{
        "detail" : data.message
      }, []]
    }
  })
  .catch(( err ) => {
    return [{
      code : "ERROR",
      detail : err.statusText,
    }, []];
  })
}

class Cta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseMessage : "",
      email : "",
      redirect : false,
      submitTrigger : false,
    };
    
    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChanged(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    let email = this.state.email
    if(email !== ""){
      this.setState({responseMessage : "", submitTrigger: true})
      // subscribe user to mailchimp account
      const [err, response] = await subscribeForMailchimp(email)
      if(err){
        this.setState({responseMessage : err.detail, submitTrigger: false})
      }else{
        console.log(response)
        this.setState({ redirect: true });
      }
    }
  };

  render() {

    if (this.state.redirect) {
      return <Redirect
        to={{
          pathname: "/angebot/",
          state: { email: this.state.email, showMailchimpMessage: true }
        }}
      />
    }

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className="cta-slogan">
              <h3 className="m-0">
                Fordern Sie jetzt Ihr persönliches Angebot für Ihre finanzielle
                Freiheit an!
              </h3>
            </div>
            <div className="cta-action">
              {/*<Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Ihre E-Mail Adresse">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#f8b273" />
                </svg>
              </Input>*/}
              <small>{this.state.responseMessage}</small>
              <form  onSubmit={this.handleSubmit}>
                <Input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChanged}
                  label="This is a label"
                  placeholder="Ihre E-Mail Adresse"
                  formGroup="desktop"
                  labelHidden
                  required
                >
                  <Button loading={this.state.submitTrigger} color="light">
                    <span style={marginTop}>
                      <svg
                        width="16"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                          fill="#f8b273"
                        />
                      </svg>
                    </span>
                  </Button>
                </Input>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// inline style
const marginTop = {
  marginTop: '6px'
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
