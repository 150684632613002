import React from 'react';
import { Link } from "react-router-dom";
import Image from '../../elements/Image';

class Article extends React.Component {
  
  render() {

    let post = this.props.post

    let imagesArr = post._embedded["wp:featuredmedia"]
    let imageObj = {
        alt_text : "",
        source_url : "",
        height : "",
        width : "",
    }
    if(imagesArr.length > 0){
        imageObj = {
            alt_text : imagesArr[0].alt_text,
            source_url : imagesArr[0].media_details.sizes.medium.source_url,
            height : imagesArr[0].media_details.sizes.medium.height,
            width : imagesArr[0].media_details.sizes.medium.width,
        }
    }

    // remove tag from text
    var div = document.createElement('div');

    // assing your HTML to div's innerHTML
    div.innerHTML = post.excerpt.rendered;

    // get all <a> elements from div
    var elements = div.getElementsByTagName('a');

    // remove all <a> elements
    while (elements[0])
      elements[0].parentNode.removeChild(elements[0])

    // get div's innerHTML into a new variable
    var excerptText = div.innerHTML;

    return (
        <div className="tiles-item" key={post.id} data-reveal-delay="100">
          <div className="tiles-item-inner has-shadow">
            <figure className="news-item-image m-0">
              <Link
                to={{
                  pathname: "/post/"+post.slug,
                  state: {
                    postID: post.id
                  },
                }}
              >
                <Image 
                src={imageObj.source_url} 
                alt={imageObj.alt_text} 
                width={344} height={imageObj.height} style={{"max-height" : "194px", "object-fit" : "cover"}} />
              </Link>
            </figure>
            <div className="news-item-content">
              <div className="news-item-body">
                <h3 className="news-item-title h4 mt-0 mb-8">
                  <Link
                    to={{
                      pathname: "/post/"+post.slug,
                      state: {
                        postID: post.id
                      },
                    }}
                  >
                    {post.title.rendered}
                  </Link>
                </h3>
                <p className="mb-16 text-sm">
                  {excerptText.replace(/<[^>]+>/g, "")}
                </p>
              </div>
              <div className="news-item-more text-xs mb-8">
                <Link
                  to={{
                    pathname: "/post/"+post.slug,
                    state: {
                      postID: post.id
                    },
                  }}
                >Weiterlesen</Link>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Article;