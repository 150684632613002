import React from "react";
// import sections
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";

class Fruchtgenussrecht extends React.Component {
  render() {
    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h1 className="mt-0 center-content">Fruchtgenussrecht</h1>
            <p>
              Das Fruchtgenussrecht räumt Ihnen hinsichtlich des verkauften
              Immobilienanteils eine eigentümerähnliche Stellung ein, die durch
              die Eintragung im Grundbuch wasserdicht abgesichert ist. Im
              Unterschied zum Wohnrecht beim{" "}
              <a href="/gesamtverkauf">Gesamtverkauf</a> oder der{" "}
              <a href="/leibrente">Leibrente</a> sind Sie beim Fruchtgenussrecht
              auch dazu berechtigt, die Immobilie zu vermieten und Mieteinkünfte
              zu erzielen.
            </p>
          </div>
        </GenericSection>

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Fruchtgenussrecht;
