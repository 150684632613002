import React from 'react';
// import sections
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import News from '../components/sections/News';
import Roadmap from '../components/sections/Roadmap';
import Calculator from '../components/sections/Calculator';
import Testimonial from '../components/sections/Testimonial';
// import Clients from '../components/sections/Clients';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';
// import some required elements
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Home extends React.Component {
  render() {

    const genericSection04Header = {
      title: 'Häufig gestellte Fragen'
    }

    return (
      <React.Fragment>
        <HeroSplit hasBgColor invertColor />
        <FeaturesTiles />
        <News className="illustration-section-01" />
        <Roadmap topOuterDivider />
        <Calculator hasBgColor pricingSlider />
        <Testimonial className="has-bg-color-cut illustration-section-02" />
        {/* <Clients topDivider bottomDivider /> */}

        <GenericSection>
          <div className="container-xs">
            <SectionHeader
              data={genericSection04Header}
              className="center-content"
            />
            <Accordion>
              <AccordionItem
                title="Wer kann vom Teilverkauf und LIQIMO profitieren?"
                active
              >
                Unser Ankaufsprofil umfasst Grundstücke (unbebaut oder bebaut
                mit Einfamilienhaus) in Österreich mit Eigentümern in der
                Altersgruppe 60+.
              </AccordionItem>
              <AccordionItem title="Wieso kann ich nur bis zu 50% der Immobilienanteile verkaufen?">
                Sie als Mehrheitseigentümer sollen weiterhin die volle Kontrolle
                über Ihre eigenen vier Wände behalten. LIQIMO unterstützt Sie
                als stiller Teilhaber.
              </AccordionItem>
              <AccordionItem title="Wie sind meine Rechte abgesichert?">
                Ihnen wird ein lebenslanges Fruchtgenussrecht hinsichtlich der
                gesamten Immobilie im Grundbuch einverleibt. Auf Anfrage kann
                auch Ihr/e Lebenspartner/ in als Begünstigte/r in das
                Fruchtgenussrecht aufgenommen werden. Darüber hinaus wird Ihnen
                ein jederzeitiges Widerkaufsrecht auf den Anteil von LIQIMO im
                Grundbuch eingeräumt. Dies ermöglicht Ihnen den Anteil von
                LIQIMO jederzeit zurückzukaufen. Weiters wird Ihnen ein
                Vorkaufsrecht auf den Anteil von LIQIMO eingeräumt. Dadurch
                sichern Sie sich gegen einen ungewollten Wechsel des
                Immobilienpartners ab.
              </AccordionItem>
              <AccordionItem title="Kann ich die Immobilie weiterhin verkaufen, vererben oder verschenken?">
                Wenn Sie das Ende der Immobilienpartnerschaft wünschen, können
                Sie Ihren Anteil verkaufen oder verschenken. Der LIQIMO-Anteil
                wird dann zum jeweiligen Marktpreis mitübertragen werden. Sollte
                eine Übertragung außerhalb des engen Familienkreises gewünscht
                sein, kommt LIQIMO ein Vorkaufsrecht zu, Ihren Anteil zum
                jeweils aktuellen Marktpreis zu erwerben.
              </AccordionItem>
              <AccordionItem title="Ab wann entstehen für mich Kosten mit LIQIMO?">
                Die Kosten der Ersteinschätzung Ihrer Immobilie sowie deren
                Bewertung durch den Sachverständigen werden von LIQIMO getragen.
                Im Gegensatz zu einem Normalverkauf fallen bei LIQIMO keine
                Maklergebühren an. Die üblichen Kaufnebenkosten
                (Vertragserrichtung, Grunderwerbsteuer, Eintragungsgebühr)
                werden von LIQIMO getragen. Ihre persönliche Einkommenssteuer
                sowie die Kosten Ihrer individuellen steuerlichen und
                rechtlichen Beratung sind von Ihnen zu tragen. Während der
                LIQIMO-Partnerschaft ist von Ihnen der Nutzungsbeitrag zu
                zahlen, den Sie im Rahmen des Ankaufsprozesses festgelegt haben.
                Am Ende der Partnerschaft erhält LIQIMO ein Entgelt für die
                Abwicklung des Verkaufsprozesses.
              </AccordionItem>
              <AccordionItem title="Was passiert, wenn ich meinen Nutzungsbeitrag nicht mehr zahlen kann?">
                In der Fruchtgenussrechtsvereinbarung ist eine großzügige
                Verzugsregelung vorgesehen, die Ihnen genug Zeit gibt,
                Liquiditätsengpässe auszugleichen. Sollte auch nach Ablauf der
                Nachfrist eine Bezahlung des Nutzungsbeitrages nicht möglich
                sein, können Sie durch den Verkauf weiterer Anteile neue
                Liquidität schaffen. Erst wenn auch ein weiterer Teilverkauf
                nicht gewünscht ist, kommt es zu einer Beendigung des
                Fruchtgenussrechts.
              </AccordionItem>
              <AccordionItem title="Was passiert bei einer Insolvenz von LIQIMO?">
                Das Team rund um LIQIMO besteht aus Experten mit jahrelanger
                Erfahrung auf dem Immobilienmarkt. Sollte es dennoch zu einer
                Insolvenz kommen, sind Sie durch Ihr grundbücherliches
                Fruchtgenussrecht abgesichert. Solange Sie mit der Bezahlung
                Ihrer Nutzungsbeiträge nicht im qualifizierten Verzug sind,
                spüren Sie keine Änderungen der Lebens- und Einkommenssituation.
                Sollten Sie mit Ihren Nutzungsbeiträgen in qualifizierten Verzug
                geraten, sind die Konsequenzen in obenstehender Frage angeführt.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>

        {/*<Cta hasBgColor invertColor split />*/}
        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Home;
