import React from 'react';
// import sections
import FeaturesTilesBenefits from '../components/sections/FeaturesTilesBenefits';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import Pricing from '../components/sections/Pricing';

class Vorteile extends React.Component {

  render() {

    return (
      <React.Fragment>
        <FeaturesTilesBenefits topDivider />
        <FeaturesTabs topDivider bottomOuterDivider />
        <Pricing hasBgColor />
      </React.Fragment>
    );
  }
}

export default Vorteile;
